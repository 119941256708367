.btn{
  background-color: var(--main-color);
  padding: 10px 20px;
  border: 1px solid #000;
  transition: 0.4s;
  border-radius: 20px;
}
.btn:hover{
  border: 1px solid #000;
}
.top-border{
  margin: 50px auto;
  display: block;
  width: 150px;
  height: 10px;
  border-radius: 20px;
  background-color: var(--main-color);
}