*{
  margin: 0;
  padding:0;
  box-sizing: border-box;
}

::-webkit-scrollbar{
  background-color: #FCFC02;
}
:root{
  --main-color:#FCFC02;
  --bg-color:#f0f0f0;
}
/* whatsapp icon */
/* WhatsAppIcon.css */
.whatsapp-icon {
  padding: 10px;
  background-color: #fff;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Adjust the z-index as needed to ensure the icon appears above other elements */
}

.whatsapp-icon img {
  width: 50px; /* Adjust the width and height as needed */
  height: 50px;
  border-radius: 50%; /* Make it circular, adjust as needed */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Optional: Add a box shadow for a subtle effect */
}
/* contact page */
.contact{
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.contact .right-contact img{
  width: 500px;
  height: 500px;
  border-radius: 50%;
}
.contact .left-contact form{
  width: 500px;
  border-radius: 50px;
}