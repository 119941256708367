.info {
  display: flex;
  align-items: center;
}
.info div {
  padding: 20px 40px;
  background-color: #fff;
  text-align: right;
  line-height: 30px;
}
.info div h3 {
  background: var(--main-color);
  font-weight: 700;
  margin: 20px 0;
  width: 445px;
  border-radius: 50px;
}
form {
  background-color: #fff;
  margin: 30px;
  padding: 50px;
}
form .input {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
}
form .input input {
  padding: 10px;
  padding-right: 15px;
  border-radius: 20px;
  background-color: var(--bg-color);
  border: none;
  outline: none;
}
form .input input:focus {
  outline: 1px solid var(--main-color);
}
form .btn {
  width: 200px;
  display: block;
  margin: 0 auto;
}
